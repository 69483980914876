<template>
  <div class="AddCertificates">
    <div class="w_layout">

      <div class="topText">
        <div class="title">
          <p>
            当前位置：四名工作室 > 话题研讨 >
            <span>发起话题</span>
          </p>
        </div>
      </div>

      <div class="form_box_div">

        <div class="top_title_box">发起话题
          <span class="temp_class_span_div"> -t62</span>
        </div>

        <el-form ref="ruleForm" :model="form" :rules="rules" label-position="right" label-width="150px" style="width: 100%; margin-left: 0px;">

          <el-form-item label="标题 :" prop="title">
            <el-input v-model="form.title" style="width: 600px" placeholder="请输入标题"></el-input>
          </el-form-item>

          <el-form-item label="内容 :" prop="content">
            <div class="pr80">
              <Editor v-model="form.content"></Editor>
            </div>
          </el-form-item>

          <el-form-item style="text-align: left;margin-top: 50px;">
            <el-button class="width148" @click="resetForm('form')">取消</el-button>
            <el-button class="width148" type="primary" style="background: rgba(255, 130, 1, 1); border: none" @click="submitForm('form')"
              :loading="save_loading_btn">提交
              <span class="temp_class_span_div">-89a</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
import { debounce, throttle } from "@/utils/utils.js";
export default {
  watch: {
    "form.content"() {
      this.$nextTick(() => {
        this.$refs['ruleForm'].validateField('content'); //单独校验
      })
    }
  },
  data() {
    return {
      save_loading_btn: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      form: {
        title: "",
        content: "",
        user_id: localStorage.getItem("id"),
        teaching_studio_id: localStorage.getItem("studioId")
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' },],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
    }
  },
  created() {
  },

  methods: {
    //取消
    resetForm() {
      this.$router.go(-1);
    },
    //发布荣誉证书
    submitForm: throttle(function () {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return false;
        };
        let reqObj = deepClone(this.form);
        this.save_loading_btn = true;
        this.$axios.post("/CreateTeachingStudioGambit", reqObj).then(() => {
          this.save_loading_btn = false;
          this.$message.success("发起话题成功")
          this.$router.go(-1);
        }).catch((err) => {
          this.save_loading_btn = false;
        });
      })
    }),
  },
};
</script>

<style lang="less" scoped>
.AddCertificates {
  background-color: #eef2fb;
}
.form_box_div {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding-right: 200px;
  padding-bottom: 30px;
}
.top_title_box {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 60px;
}
.topText {
  .title {
    height: 48px;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    p {
      span {
        color: #ff8201;
      }
    }
  }
}
</style>